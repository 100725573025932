@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


.default-theme {
    /* General colors */
    --color-gray-50: #F9FAFB;
    --color-gray-100: #F3F4F6;
    --color-gray-200: #E5E7EB;
    --color-gray-300: #D1D5DB;
    --color-gray-400: #9CA3AF;
    --color-gray-500: #6B7280;
    --color-gray-600: #4B5563;
    --color-gray-700: #374151;
    --color-gray-800: #1F2937;
    --color-gray-900: #111827;

    --color-blue-900: #1006ed;

    --color-primary: #124477;
    --color-primary-50: #EEF2FF;
    --color-primary-100: #E0E7FF;
    --color-primary-200: #C7D2FE;
    --color-primary-300: #A5B4FC;
    --color-primary-400: #2486e9;
    --color-primary-500: #1f73c8;
    --color-primary-600: #1b67b3;
    --color-primary-700: #185a9d;
    --color-primary-800: #144c85;
    --color-primary-900: #124477;

    --color-secondary: #AAAAAA;
    --color-secondary-50: #EEF2FF;
    --color-secondary-100: #E0E7FF;
    --color-secondary-200: #C7D2FE;
    --color-secondary-300: #A5B4FC;
    --color-secondary-400: #2486e9;
    --color-secondary-500: #1f73c8;
    --color-secondary-600: #1b67b3;
    --color-secondary-700: #185a9d;
    --color-secondary-800: #144c85;
    --color-secondary-900: #124477;

    --color-popup: #fefefe;
    --color-inverse: #fff;

    --color-opacity-inverse: rgba(255, 255, 255, .6);

    --color-field: #ffffff;

    /* Font */
    --font-display: 'Nunito', sans-serif;
    --font-body: 'Nunito', sans-serif;

    --font-weight-normal: 400;
    --font-weight-display: 600;
    --font-weight-btn: 600;

    /* Button */
    --rounded-btn: 9999px;

    /* React-select colors */
    --ring-primary: rgb(79, 70, 229) 0px 0px 0px 0px, rgb(79, 70, 229) 0px 0px 0px 2px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;

    color: #495057;
}

.default-theme.dark {
    --color-gray-50: #252525;
    --color-gray-100: #181818;
    --color-gray-200: #383838;
    --color-gray-300: #2f2f2f;
    --color-gray-400: #9CA3AF;
    --color-gray-500: #898989;
    --color-gray-600: #D1D5DB;
    --color-gray-700: #b8b8b8;
    --color-gray-800: #1f2937;
    --color-gray-900: #e2e8f0;

    --color-field: #232223;
    --color-inverse: #212121;

    --color-opacity-inverse: rgba(0, 0, 0, .6);

    color: #a4a2a2;
}

@layer components {
    .form-control {
        @apply block text-tm-gray-900 w-full bg-field shadow-sm py-2 px-2 focus:outline-none focus:bg-field focus:ring-0 focus:border-button sm:text-sm disabled:bg-tm-gray-400 disabled:text-inverse focus:text-tm-gray-900 rounded-none
    }

    .form-control-condensed {
        @apply block text-xs text-tm-gray-900 leading-6 w-full border border-tm-gray-600 bg-field shadow-sm py-0.5 px-2 focus:outline-none focus:bg-field focus:ring-0 focus:border-button disabled:bg-tm-gray-500 disabled:text-tm-gray-500 focus:text-tm-gray-900 rounded-none
    }

    .checkbox {
        @apply focus:ring-button focus:ring-offset-inverse h-4 w-4 text-primary border-tm-gray-300 rounded bg-tm-gray-100 cursor-pointer
    }

    .radio {
        @apply focus:ring-button focus:ring-offset-inverse h-4 w-4 text-primary border-tm-gray-300 rounded-full bg-tm-gray-100 cursor-pointer
    }

    .form-error {
        @apply ring-red-300 ring-4
    }

    .btn {
        @apply relative inline-flex items-center text-sm font-medium
    }

    .btn-text {
        @apply border-transparent hover:text-primary-700 text-primary font-medium border border-transparent disabled:cursor-default disabled:text-tm-gray-300 disabled:opacity-50
    }

    .btn-primary {
        @apply px-5 py-2 rounded-btn text-white bg-primary hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-inverse focus:ring-primary-500 shadow-sm cursor-pointer disabled:cursor-default disabled:border-tm-gray-300 disabled:bg-tm-gray-300 disabled:text-tm-gray-100
    }

    .btn-secondary {
        @apply px-5 py-2 rounded-btn text-white bg-secondary hover:bg-secondary-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary shadow-sm cursor-pointer disabled:cursor-default disabled:border-tm-gray-300 disabled:bg-tm-gray-300 disabled:opacity-75 disabled:text-inverse
    }

    .btn-outline {
        @apply px-5 py-2 border-tm-gray-300 rounded-btn text-tm-gray-700 bg-transparent border cursor-pointer hover:text-tm-gray-900 hover:border-tm-gray-300 hover:bg-transparent focus:outline-none focus-visible:bg-primary-500 focus-visible:text-inverse disabled:cursor-default disabled:border-tm-gray-300 disabled:text-tm-gray-300 disabled:hover:bg-transparent disabled:opacity-75
    }

    .btn-outline-secondary {
        @apply px-5 py-2 border-tm-gray-300 rounded-btn text-tm-gray-700 bg-transparent border shadow-sm cursor-pointer hover:text-tm-gray-700 hover:border-tm-gray-700 hover:bg-transparent focus:outline-none focus:border-transparent focus:ring-2 focus:ring-primary disabled:cursor-default disabled:border-tm-gray-200 disabled:text-tm-gray-700 disabled:hover:bg-transparent disabled:opacity-75
    }

    .btn-table-action {
        @apply p-2 rounded-full text-tm-gray-600 hover:bg-tm-gray-200 hover:text-tm-gray-700 disabled:hover:bg-transparent focus:outline-none focus-visible:ring-2 ring-offset-2 ring-primary
    }

    .h-dialog-body {
        @apply max-h-[calc(100vh-12rem)] overflow-auto
    }
}

.bg-opacity-inverse {
    background: var(--color-opacity-inverse);
}

.bg-archived {
    background-color: #F3F4F6;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%23FFFFFF' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%23FFFFFF' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
}

.dark .bg-archived {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%23383838' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%23383838' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
}

.select-css-search input:focus {
    box-shadow: none;
}

/* Notifications */
.notification-fade-out {
    animation-name: notificationFadeOut;
    animation-duration: .5s;
    /*animation-delay: 6s;*/
    animation-fill-mode: forwards;
    overflow: hidden;
}

.notification-infinite {
    animation: none;
}

.select-from-library-button,
.selected-library-item {
    border: 1px solid #424242;
    height: 110px;
}

.select-from-library-button .card,
.selected-library-item .card {
    box-shadow: initial;
}

.select-from-library-button {
    cursor: pointer;
    border-style: dashed;
}

@keyframes notificationFadeOut {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
        height: 0;
        border-top: 0;
        border-bottom: 0;
        opacity: 0
    }
}

@keyframes notificationFadeOutNow {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
        height: 0;
        border-top: 0;
        border-bottom: 0;
        opacity: 0
    }
}

.notification-fade-out .close {
    display: none;
}

.notification-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 25px;
    bottom: 0;
}

.body-height {
    height: calc(100vh - 4rem);
}

html {
    scroll-behavior: smooth;
}

.btn-sm {
    padding: 0.25rem 1rem;
    font-size: .75rem;
}

.custom-forms-tab-header {
    border-bottom: 1px solid #d1d5db;
    padding-bottom: 10px;
}

.custom-forms-content {
    height: 65vh;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding-right: 10px;
}
.excel-to-html-table {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
}
.excel-to-html-table body {
    font-family: "Open Sans", sans-serif;
    line-height: 1.25;
}

.excel-to-html-table table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    width: 100%;
}

.excel-to-html-table table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
}

.excel-to-html-table table tr {
    border: 1px solid #ddd;
    padding: .35em;
}

.excel-to-html-table table tr:nth-child(even) {
    background: #f8f8f8;
}

.excel-to-html-table table th,
.excel-to-html-table table td {
    padding: .625em;
    text-align: left;
}

.excel-to-html-table table th {
    background: #999;
    color: #fff;
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
}

.excel-to-html-table table td {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

#atlwdg-trigger {
    z-index: 50;
}

.long-cell-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
}

.animation-pulse {
    /*box-shadow: 0 0 0 20px rgba(229,62,62, 0.5);
    transform: scale(0.8);*/
    animation: warning-pulse 1.7s infinite;
}

@keyframes warning-pulse {
    0% {
        transform: scale(0.8);
        box-shadow: 0 0 0 0 rgba(229, 62, 62, 1);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(229, 62, 62, 0);
    }

    100% {
        transform: scale(0.8);
    }
}
